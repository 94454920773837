import React, { useState } from "react";
import { Box, Form, Heading, Button, Anchor, Image, Text } from "../../components/elements";
import IconField from "../../components/fields/IconField";
import data from "../../data/master/login.json";
import mainLogo from '../../assets/images/logos.png'
import { useNavigate } from "react-router-dom";

export default function Login() {
    const[username, setUsername] = useState('')
    const[password, setPassword] = useState('')
    const navigate = useNavigate()

    const onSubmit=(e)  => {
        e.preventDefault()
        if(username === 'admin@admin.com' && password === 'admin12345'){
            navigate('/dashboard')
        }else{
            alert('Wrong Username or Password')
        }
    }

    return (
        <Box className="mc-auth align-items-center justify-content-center d-flex">
            <Image
                src={ data?.pattern.src } 
                alt={ data?.pattern.alt }
                className="mc-auth-pattern"  
            />
            <Box className="mc-auth-group">
                <img 
                    src = { mainLogo }
                    alt = "main vigilant logo"
                    href = "/"
                    className = "mc-auth-logo"
                    width={80}
                   
                    
                />
                <Heading as="h4" className="mc-auth-title">Admin Login </Heading>
                <Form className="mc-auth-form form-group"  method="post">
                    <div className="form-group">
                   <input type="email" className="form-control bg-white" name="username"  onChange={(e) => setUsername(e.target.value)} placeholder="Input your admin username" />
                   </div>

                   <div className="form-group mt-3 mb-4">
                   <input type="password" className="form-control bg-white"  onChange={(e) => setPassword(e.target.value)} name="password" placeholder="**********" />
                   </div>



                    <input type="submit" onClick={onSubmit} className="mc-auth-btn h-sm" value="Sign In" />
                   
                </Form>
                
            </Box>
        </Box>
    );
}